export const lowIncomeHighPotentialCountryCodes = [
  "ID", // Indonesia
  "AR", // Argentina
  "VN", // Vietnam
  "PH", // Philippines
  "TH", // Thailand
  "EG", // Egypt
  "TR", // Turkey
  "ZA", // South Africa
  "MY", // Malaysia
  "SA", // Saudi Arabia
  "PK", // Pakistan
  "NG", // Nigeria
  "CO", // Colombia
  "PE", // Peru
  "MA", // Morocco
  "CL", // Chile
  "AE", // United Arab Emirates
  "KW", // Kuwait
  "QA", // Qatar
  "JO", // Jordan
];
