import { Text, Image, Box, Flex, Button, Divider } from "@chakra-ui/react";
import FeatureCheckList from "./feature-check-list";
import { Section } from "./section";
import { LandingLocale } from "../localization/landing";

interface Props {
  l: LandingLocale;
  onButtonClick: () => void;
}

type Feature = {
  image: string;
  title: string;
  bullets: string[];
  imageSide: "left" | "right";
};

// Features are now localized using `l`
const getLocalizedFeatures = (l: LandingLocale): Feature[] => [
  {
    image: "/images/landing/chat-1.jpeg",
    title: l["feature.practiceSpeaking.title"],
    bullets: [
      l["feature.practiceSpeaking.bullet1"],
      l["feature.practiceSpeaking.bullet2"],
      l["feature.practiceSpeaking.bullet3"],
      l["feature.practiceSpeaking.bullet4"],
    ],
    imageSide: "left",
  },
  {
    image: "/images/landing/chat-cn.jpeg",
    title: l["feature.worldOfLanguages.title"],
    bullets: [
      l["feature.worldOfLanguages.bullet1"],
      l["feature.worldOfLanguages.bullet2"],
      l["feature.worldOfLanguages.bullet3"],
      l["feature.worldOfLanguages.bullet4"],
    ],
    imageSide: "right",
  },
  {
    image: "/images/landing/feedback.jpeg",
    title: l["feature.actionableFeedback.title"],
    bullets: [
      l["feature.actionableFeedback.bullet1"],
      l["feature.actionableFeedback.bullet2"],
      l["feature.actionableFeedback.bullet3"],
      l["feature.actionableFeedback.bullet4"],
    ],
    imageSide: "left",
  },
  {
    image: "/images/landing/pronunciation.jpeg",
    title: l["feature.pronunciationPractice.title"],
    bullets: [
      l["feature.pronunciationPractice.bullet1"],
      l["feature.pronunciationPractice.bullet2"],
      l["feature.pronunciationPractice.bullet3"],
      l["feature.pronunciationPractice.bullet4"],
    ],
    imageSide: "right",
  },
  {
    image: "/images/landing/wordbook.jpeg",
    title: l["feature.interactiveWordbook.title"],
    bullets: [
      l["feature.interactiveWordbook.bullet1"],
      l["feature.interactiveWordbook.bullet2"],
      l["feature.interactiveWordbook.bullet3"],
      l["feature.interactiveWordbook.bullet4"],
    ],
    imageSide: "right",
  },
];

export default function FeaturePresentation(props: Props) {
  const features = getLocalizedFeatures(props.l);

  return (
    <Box>
      {features.map((feature, index) => (
        <Box
          key={index}
          backgroundColor={feature.imageSide === "right" ? "white" : "white"}
        >
          <Section>
            <Flex
              mx="auto"
              my={{ base: 0, lg: 12 }}
              flexDir={{
                base: "column",
                lg: feature.imageSide === "right" ? "row-reverse" : "row",
              }}
              alignItems={{ lg: "center" }}
            >
              <Box flex="1" maxW={{ base: "100%", lg: "60%" }} px={5}>
                <Image
                  borderRadius={"lg"}
                  boxShadow={"lg"}
                  src={feature.image}
                  alt={feature.title}
                  w="full"
                  maxW="600px"
                />
              </Box>
              <Flex
                flex="1"
                maxW={{ base: "100%", lg: "40%" }}
                py={{ base: 5, lg: 0 }}
                px={5}
                flexDir="column"
              >
                <Text
                  fontWeight={"bold"}
                  lineHeight="1.2"
                  fontSize="4xl"
                  as="h3"
                >
                  {feature.title}
                </Text>

                <Box>
                  <FeatureCheckList
                    my={5}
                    fontSize="md"
                    checkMarkColor={"#000"}
                    features={feature.bullets}
                    spacing={6}
                    listItemSpacing={2}
                    l={props.l}
                  />

                  <Button
                    size="md"
                    mt={2}
                    variant="outline"
                    colorScheme="brand.green"
                    borderColor="brand.green.500"
                    color="brand.green.500"
                    onClick={props.onButtonClick}
                  >
                    {props.l["feature.button.startTrial"]}
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Section>
          <Divider maxW="1000px" m="0 auto" />
        </Box>
      ))}
    </Box>
  );
}
