import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  Button,
} from "@chakra-ui/react";
import { StdLink } from "./StdLink";
import { LandingLocale } from "../localization/landing";

interface Props {
  l: LandingLocale;
}

const faqItems = (l: LandingLocale) => [
  [l["faq.question1"], l["faq.answer1"]],
  [l["faq.question2"], () => <FaqMe l={l} />],
  [l["faq.question3"], () => <GoodForBeginners l={l} />],
  [l["faq.question4"], l["faq.answer4"]],
  [l["faq.question5"], l["faq.answer5"]],
  [l["faq.question6"], l["faq.answer6"]],
  [l["faq.question7"], l["faq.answer7"]],
  [l["faq.question8"], l["faq.answer8"]],
  [l["faq.question9"], l["faq.answer9"]],
  [l["faq.question10"], l["faq.answer10"]],
  [l["faq.question11"], l["faq.answer11"]],
  [l["faq.question12"], l["faq.answer12"]],
];

export default function LandingFaq({ l }: Props) {
  return (
    <Accordion>
      {faqItems(l).map(([question, answer], index) => (
        <AccordionItem key={index}>
          <Heading size="xs">
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                {typeof question === "function" ? question() : question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            {typeof answer === "function" ? answer() : answer}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

function GoodForBeginners({ l }: { l: LandingLocale }) {
  return (
    <>
      <Text>{l["faq.answer3"]}</Text>
      <Button
        my={3}
        colorScheme="brand.primary"
        color="brand.gray.700"
        size="xs"
        px={5}
        href="/guide/beginner-intro"
        as="a"
        target="_blank"
      >
        {l["faq.button.gettingStartedGuide"]}
      </Button>
    </>
  );
}

function FaqMe({ l }: { l: LandingLocale }) {
  return (
    <>
      <Text>
        {l["faq.answer2.part1"]}{" "}
        <StdLink
          target="_blank"
          href="https://datacvr.virk.dk/enhed/virksomhed/43598171?fritekst=43598171&sideIndex=0&size=10"
          text="Aidia ApS"
          rel="nofollow"
        />{" "}
        {l["faq.answer2.part2"]}
      </Text>
    </>
  );
}
